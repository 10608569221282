import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CartModel } from './models/cart.model';
import { MenuModel } from './models/menu.model';
import { Plugins } from '@capacitor/core';
import { RestaurantInfoModel } from './models/restaurant-info.model';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cart = new BehaviorSubject<CartModel>(null);
  private cartItemCount = new BehaviorSubject(0);
  private cartItemAmount = new BehaviorSubject(0);

  constructor() {
    this.updateCart();
  }

  getCart(){
    return this.cart;
  }

  getCartItemCount(){
    return this.cartItemCount;
  }

  getCartItemAmount(){
    return this.cartItemAmount;
  }

  async addCartItem(menu:MenuModel,hotelId:number){
    let cart = await this.retriveCart();
    if(cart){
      const index = cart.Menus.findIndex(r => r.MenuID == menu.MenuID && r.QuantityDetails === menu.QuantityDetails);
      if(index == -1){
        menu.CartQuantity = 1;
        cart.Menus.push(menu);
      }else{
        let menus = [...cart.Menus];
        menus[index] = {...menus[index], CartQuantity: menus[index].CartQuantity + 1};
        cart.Menus = menus;
      }
      await this.storeCart(cart);
    }else{
      cart = new CartModel();
      cart.HotelID = hotelId;
      menu.CartQuantity = 1;
      cart.Menus.push(menu);
      await this.storeCart(cart);
    }
  }

  async removeCartItem(menu:MenuModel){
    let cart = await this.retriveCart();
    if(cart){
      const index = cart.Menus.findIndex(r => r.MenuID == menu.MenuID && r.QuantityDetails === menu.QuantityDetails);
      let menus = [...cart.Menus];
      menus[index] = {...menus[index], CartQuantity: menus[index].CartQuantity - 1};
      cart.Menus = menus.filter(r => r.CartQuantity > 0);

      if(cart.Menus.length == 0){
        await this.removeCart();
      }else{
        await this.storeCart(cart);
      }
    }
  }

  async storeCart(cart: CartModel){
    const data = JSON.stringify(cart);
    await Plugins.Storage.set({key:'cart',value:data});
    this.updateCart();
  }

  retriveCart(){
    return Plugins.Storage.get({key:'cart'})
    .then(data =>{
      if (!data || !data.value) {
        return null;
      }else{
        return JSON.parse(data.value) as CartModel;
      }
    });
  }

  async updateCart(){
    let cart = await this.retriveCart();
    if(cart){
      let count = cart.Menus.map(x => x.CartQuantity).reduce((p,c)=>{return p+c});
      let amount = cart.Menus.map(x => x.CartQuantity * x.Price).reduce((p,c)=>{return p+c});
      this.cartItemCount.next(count);
      this.cartItemAmount.next(amount);
      this.cart.next(cart);
    }
  }

  async removeCart(){
    await Plugins.Storage.remove({key:'cart'});
    this.cart.next(null);
    this.cartItemCount.next(0);
    this.cartItemAmount.next(0);
  }
}
