import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-rounting.module';
import { IonicModule } from '@ionic/angular';
import { CategoryPopupComponent } from './category-popup/category-popup.component';
import { FormsModule } from '@angular/forms';
import { CouponPopupComponent } from './coupon-popup/coupon-popup.component';
import { CelebrationPopupComponent } from './celebration-popup/celebration-popup.component';

@NgModule({
  declarations: [
    CategoryPopupComponent,
    CouponPopupComponent,
    CelebrationPopupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedRoutingModule
  ],
  exports: [
    CategoryPopupComponent,
    CategoryPopupComponent
  ]
})
export class SharedModule { }
