import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AuthModel } from 'src/app/models/auth.model';
import { CouponModel } from 'src/app/models/coupon.model';
import { ServerApiService } from 'src/app/server-api.service';
import { UtilService } from '../../util.service';

@Component({
  selector: 'app-coupon-popup',
  templateUrl: './coupon-popup.component.html',
  styleUrls: ['./coupon-popup.component.scss'],
})
export class CouponPopupComponent implements OnInit {

  @Input() coupons: CouponModel[] = [];
  @Input() hotelId:number=0;
  giftCpCode='';
  isAuthenticated : boolean = false;
  customerId:number=0;
  authenticatedUser : AuthModel;

  constructor(private popoverCtrl:PopoverController,private utilService:UtilService,private serverApiService:ServerApiService) { }

  ngOnInit() {
  }

  async DismissClick() {
    await this.popoverCtrl.dismiss();
  }

  ionViewWillEnter(){
    this.serverApiService.userIsAuthenticated
    .subscribe(
      isAuthenticated => {
        this.isAuthenticated = isAuthenticated;
    });
    this.serverApiService.user
    .subscribe(
      user => {
        this.authenticatedUser = user;
    });
  }

  applyGiftCoupon(){
    if(this.giftCpCode===''){
      this.utilService.showToast("Please enter Gift Coupon Code");
      return;
    }
    if(this.isAuthenticated){
      this.customerId=this.authenticatedUser.userId;
    }

    this.serverApiService.getGiftCoupon(this.hotelId,this.giftCpCode.toUpperCase(),this.customerId.toString())
    .subscribe(
      (res: CouponModel) => {
        //console.log(res);
        this.popoverCtrl.dismiss(
          {
            result : {
              coupon : res
            }
          },
          'confirm'
        );
      },
      err => {
        console.log(err);
        let msg = 'Unable to apply coupon.';
        if(err.error?.Message){
          msg = err.error.Message;
        }
        this.utilService.showToast(msg);
      }
    );

  }

  onSubmit(coupon:CouponModel){
    this.popoverCtrl.dismiss(
      {
        result : {
          coupon : coupon
        }
      },
      'confirm'
    );
  }
}
