import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { CategoryModel } from '../../models/category.mode'

@Component({
  selector: 'app-category-popup',
  templateUrl: './category-popup.component.html',
  styleUrls: ['./category-popup.component.scss'],
})
export class CategoryPopupComponent implements OnInit {

  @Input() categories: CategoryModel[] = [];

  constructor(private popoverCtrl:PopoverController) { }

  ngOnInit() {}

  onSubmit(category:string){
    this.popoverCtrl.dismiss(
      {
        result : {
          category : category
        }
      },
      'confirm'
    );
  }
}
