import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-celebration-popup',
  templateUrl: './celebration-popup.component.html',
  styleUrls: ['./celebration-popup.component.scss'],
})
export class CelebrationPopupComponent implements OnInit {

  constructor(private popoverCtrl:PopoverController) { }

  ngOnInit() {
    setTimeout(() => {
      this.popoverCtrl.dismiss();
    }, 2000)
  }

}
